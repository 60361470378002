import React, { Component } from 'react';
import { Ajax } from '../ajax/Ajax';

class PageContents extends Component {
	
	constructor(props) {
		super(props);
		this.state = {page : null};
	}
	
	componentWillMount() {
		var obj = this;
		var slug = this.props.match.params.slug;
		Ajax.getPageContents(slug).then(function(page) {
			obj.setState({page: page});			
		});
	}
	
	render() {
		var page = this.state.page;
		var output = null;
		if (page)
			output = (<div className="Page"><div className="PageTitle"  dangerouslySetInnerHTML={ {__html: page.title} } /><div className="PageContent" dangerouslySetInnerHTML={ {__html: page.content} } /></div>);
		return output;
	}
}

export { PageContents };