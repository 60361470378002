import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { WithContext as ReactTags } from 'react-tag-input';
import { Release } from './Release';

class Releases extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			releases: this.props.releases, 
			shownReleases: this.props.releases, 
			player: this.props.player, 
			suggestions: [], 
			genres: {},
			searchFilter: this.props.shopFilter.filter, 
			vinyl: this.props.shopFilter.inStock.vinyl, 
			tape: this.props.shopFilter.inStock.tape, 
			cd: this.props.shopFilter.inStock.cd,
			allInStock: this.props.shopFilter.inStock.all,
			urbanWaves: this.props.shopFilter.label.urbanWaves, 
			radioJuicy: this.props.shopFilter.label.radioJuicy, 
			all: this.props.shopFilter.label.all, 
			saveFilter: this.props.updateFilter,
			artists: [], 
			artistFilter: []};

		this.handleAddFilter = this.handleAddFilter.bind(this);
		this.handleDeleteFilter = this.handleDeleteFilter.bind(this);
		this.filterReleases = this.filterReleases.bind(this);
		this.selectRadioJuicy = this.selectRadioJuicy.bind(this);
		this.selectUrbanWaves = this.selectUrbanWaves.bind(this);
		this.selectAll = this.selectAll.bind(this);
		this.selectTape = this.selectTape.bind(this);
		this.selectVinyl = this.selectVinyl.bind(this);
		this.selectCD = this.selectCD.bind(this);
		this.selectAllInStock = this.selectAllInStock.bind(this);
		this.setupFilters = this.setupFilters.bind(this);
	}

	setupFilters() {
		var releases = this.state.releases;
		var suggestionsSet = new Set();
		var artistsSet = new Set();
		var genresSet = new Set();
		for (var i = 0; i < releases.length; i++) {
			var release = releases[i];
			var searchTerms = release.searchableTerms;
			for (var j = 0; j < searchTerms.length; j++) {
				suggestionsSet.add(searchTerms[j]);
			}
			var artists = release.artist;
			for (var k = 0; k < artists.length; k++) {
				artistsSet.add(artists[k]);
			}
			var genres = release.genre;
			for (var l = 0; l < genres.length; l++) {
				genresSet.add(genres[l]);
			}
		}
		var suggestions = [];
		var artists = [];
		var genres = {};
		suggestionsSet.forEach((val1, val2, set) => {
			suggestions.push({id: val1, text: val1});
		});
		artistsSet.forEach((val1, val2, set) => {
			artists.push({id: val1, text: val1});
		});
		genresSet.forEach((val1, val2, set) => {
			if (this.state.genres[val1] === undefined) {
				genres[val1] = false;
			}				
			else {
				genres[val1] = this.state.genres[val1];
			}
		});
		this.setState({suggestions: suggestions, artists: artists, genres: genres});
	}

	componentWillMount() {
		this.setupFilters();
		this.filterReleases();
	}

	componentDidUpdate(prevProps) {
		var obj = this;
		if (this.props.releases.length != prevProps.releases.length) {
			this.setState({releases: this.props.releases}, () => { obj.filterReleases(); obj.setupFilters() });
		}
	}

	filterReleases() {
        var obj = this;
        var releases = obj.state.releases;
        var filter = obj.state.searchFilter;
		var genres = obj.state.genres;
		var persistFilter = {
			label: {all: obj.state.all, urbanWaves: obj.state.urbanWaves, radioJuicy: obj.state.radioJuicy},
			inStock: {all: obj.state.allInStock, vinyl: obj.state.vinyl, tape: obj.state.tape, cd: obj.state.cd},
			filter: filter,
			genres: genres
		}
		var useGenreFilter = false;
		Object.entries(genres).map((genre) => {
			useGenreFilter = useGenreFilter || genre[1];
		});
		this.state.saveFilter(persistFilter);
        if (filter.length === 0) {
			var shownReleases = releases.filter((release) => {
				if (obj.state.all) {
					return true;
				}
				if (! obj.state.urbanWaves && release.urbanWaves) {
					return false;
				}
				if (! obj.state.radioJuicy && release.radioJuicy) {
					return false;
				}
				return true;
			});
			shownReleases = shownReleases.filter((release) => {
				if (obj.state.vinyl && release.vinyl) {
					return true;
				}
				if (obj.state.cd && release.cd) {
					return true;
				}
				if (obj.state.tape && release.tape) {
					return true;
				}
				if (obj.state.allInStock) {
					return true;
				}
				return false;
			});
			shownReleases = shownReleases.filter((release) => {
				if (useGenreFilter) {
					var show = false;
					var rGenres = release.genre;
					for (var g = 0; g < rGenres.length; g++) {
						show = show || obj.state.genres[rGenres[g]];
					}
					return show;
				} else {
					return true;
				}				
			});
            obj.setState({shownReleases: shownReleases});
        } else {
            var tags = [];
            for (var i = 0; i < filter.length; i++) {
                tags.push(filter[i].id.toLowerCase());
            }
            var shownReleases = releases.filter((release) => {
				if (obj.state.all) {
					return true;
				}
				if (! obj.state.urbanWaves && release.urbanWaves) {
					return false;
				}
				if (! obj.state.radioJuicy && release.radioJuicy) {
					return false;
				}
				return true;
            });
			shownReleases = shownReleases.filter((release) => {
				if (obj.state.vinyl && release.vinyl) {
					return true;
				}
				if (obj.state.cd && release.cd) {
					return true;
				}
				if (obj.state.tape && release.tape) {
					return true;
				}
				if (obj.state.allInStock) {
					return true;
				}
				return false;
			});			
			shownReleases = shownReleases.filter((release) => {
				for (var j = 0; j < release.searchableTerms.length; j++) {					
                    var term = release.searchableTerms[j];
                    if (tags.includes(term.toLowerCase())) {
                        return true;
                    }
                }
                return false;
			});
			shownReleases = shownReleases.filter((release) => {
				if (useGenreFilter) {
					var show = false;
					var rGenres = release.genre;
					for (var g = 0; g < rGenres.length; g++) {
						show = show || obj.state.genres[rGenres[g]];
					}
					return show;
				} else {
					return true;
				}				
			});
            obj.setState({shownReleases: shownReleases});
        }                
    }

    handleDeleteFilter(i) {
        var filter = this.state.searchFilter;
        filter = filter.filter((tag, index) => index !== i);
        this.setState({searchFilter: filter}, this.filterReleases);
       
    }
 
    handleAddFilter(tag) {
        var value = tag.id;
        var trimmedTag = {};
        if (value !== undefined) {
            value = value.trim();
            trimmedTag = {id: value, text: value};
        }
        if (value !== undefined && value !== "") {
            var filter = this.state.searchFilter;
            filter = [...filter, trimmedTag];            
            this.setState({searchFilter: filter}, this.filterReleases);
        }        
    }

	selectAll() {
		this.setState({urbanWaves: false, radioJuicy: false, all: true}, this.filterReleases);
	}

	selectUrbanWaves() {
		this.setState({urbanWaves: true, radioJuicy: false, all: false}, this.filterReleases);
	}

	selectRadioJuicy() {
		this.setState({radioJuicy: true, urbanWaves: false, all: false}, this.filterReleases);
	}

	selectAllInStock() {
		this.setState({allInStock: true, vinyl: false, tape: false}, this.filterReleases);
	}

	selectVinyl() {
		if (this.state.vinyl && (! this.state.tape) && (! this.state.cd)) {
			this.setState({allInStock: true, vinyl: false, tape: false, cd: false}, this.filterReleases);
		} else {
			this.setState({allInStock: false, vinyl: ! this.state.vinyl}, this.filterReleases);
		}
		
	}

	selectTape() {
		if (this.state.tape && (! this.state.vinyl) && (! this.state.cd)) {
			this.setState({allInStock: true, vinyl: false, tape: false, cd: false}, this.filterReleases);
		} else {
			this.setState({allInStock: false, tape: ! this.state.tape}, this.filterReleases);
		}
	}

	selectCD() {
		if (this.state.cd && (! this.state.vinyl) && (! this.state.tape)) {
			this.setState({allInStock: true, vinyl: false, tape: false, cd: false}, this.filterReleases);
		} else {
			this.setState({allInStock: false, cd: ! this.state.cd}, this.filterReleases);
		}
	}

	toggleGenre(value) {
		var obj = this;
		return () => {
			var genres = obj.state.genres;
			genres[value] = !genres[value];
			obj.setState({genres: genres}, obj.filterReleases);
		}
	}
	
	render() {
		var obj = this;
		var filter = this.state.searchFilter;
		var shownReleases = this.state.shownReleases;
		var player = this.state.player;
		var suggestions = this.state.suggestions;
		var genres = this.state.genres;
		var output = (
		 <div className="Shop">
			 <div className="Filter">
			 	<div className="FilterTitle">Label <FontAwesome name="tags"/></div>
				 <div className={this.state.all ? "FilterLabel FilterLabelSelected" : "FilterLabel"}  onClick={this.selectAll}>ALL</div>
				 <div className={this.state.urbanWaves ? "FilterLabel FilterLabelSelected" : "FilterLabel"}  onClick={this.selectUrbanWaves}>Urban Waves</div>
				 <div className={this.state.radioJuicy ? "FilterLabel FilterLabelSelected": "FilterLabel"} onClick={this.selectRadioJuicy}>Radio Juicy</div>
				<div className="FilterTitle">Filter <FontAwesome name="filter"/></div>
				<div className="FilterInput">
					<ReactTags tags={filter}									
						handleDelete={this.handleDeleteFilter}
						handleAddition={this.handleAddFilter}
						handleInputBlur={(value) => { obj.handleAddFilter({id: value, text: value}); }}
						allowDragDrop={false}
						placeholder="Title/Artist/Genre"
						suggestions={suggestions}
						inline
					/>
				</div>
				<div className="FilterTitle">In Stock <FontAwesome name="shopping-basket"/></div>
				<div className={this.state.vinyl ? "FilterLabel FilterLabelSelected" : "FilterLabel"}  onClick={this.selectVinyl}>Vinyl</div>
				<div className={this.state.tape ? "FilterLabel FilterLabelSelected" : "FilterLabel"}  onClick={this.selectTape}>Tape</div>
				<div className={this.state.cd ? "FilterLabel FilterLabelSelected" : "FilterLabel"}  onClick={this.selectCD}>CD</div>
				<div className="FilterTitle">Genres <FontAwesome name="bookmark"/></div>
				<div className="FilterGenres">
				{ Object.entries(genres).map((genre, idx) => {
					return (
						<div className={genre[1] ? "FilterGenre FilterGenreSelected" : "FilterGenre"} key={idx} onClick={obj.toggleGenre(genre[0])} dangerouslySetInnerHTML={ {__html: genre[0]} }></div>
					)	
				})}
				</div>
			 </div>
			 <div className="Releases">
			{
				shownReleases ? shownReleases.map(function(release, key) {
					if (release.show)
						return (<Release key={key} title={release.title} artist={release.artist} cover={release.cover} player={player} release={release} slug={release.slug} />);
					else
						return null;
				}) : null
			}			
		</div>	
		 </div>
			
		)
		return output;
	}
}

export { Releases };