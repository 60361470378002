import 'whatwg-fetch';
import $ from 'jquery';
import config from '../config/Config';

var WooCommerceAPI = require('woocommerce-api');

var wpBase = config.wp_base;

var WooCommerce = new WooCommerceAPI({
  url: wpBase,
  consumerKey: config.wc_consumerKey,
  consumerSecret: config.wc_consumerSecret,
  wpAPI: true,
  version: 'wc/v3',
  queryStringAuth: true // Force Basic Authentication as query string true and using under HTTPS
});



class AjaxCalls {
	
	_formatDate(date) {
		var monthNames = [
		"January", "February", "March",
		"April", "May", "June", "July",
		"August", "September", "October",
		"November", "December"
		];

		var day = date.getDate();
		var monthIndex = date.getMonth();
		var year = date.getFullYear();

		return day + ' ' + monthNames[monthIndex] + ' ' + year;
	}
	
	getRequest(url) {
		var init = {
		  method: "GET"
		}; 
		var request = new Request(url, init);
		return request;
	}
	
	postRequest(url, data) {
		var textData = "";
		for (var key in data) {
			textData = textData + key + "=" + data[key] + "&";
		}
		textData = textData.substring(0, textData.length - 1);
		//console.log(textData);
		var init = {
			method: "POST",
			body: textData 
		}; 
		var request = new Request(url, init);
		return request;
	}

	getRawRequest(url, headers) {
		var init = {
		  method: "GET",
		  headers: headers
		}; 
		var request = new Request(url, init);
		return request;
	}

	postRawRequest(url, data, headers) {
		var init = {
			method: "POST",
			body: data,
			headers: headers
		}; 
		var request = new Request(url, init);
		return request;
	}
	
	getText(url) {
		return fetch(url)
		.then(function(response) {
			return response.text();
		})
		.catch(function(error) {
			console.log(error);
		});
	}
	
	getJson(url) {
		return fetch(url)
		.then(function(response) {
			return response.json();
		})
		.catch(function(error) {
			console.log(error);
		});
	}

	cacheReleases(releases) {
		var request = this.postRawRequest(config.base + "cache/index.php", JSON.stringify(releases), {"x-cache-auth": "broccoli"});
		return this.getJson(request).then((result) => {
			console.log(result);
		});
	}

	getCachedReleases() {
		var request = this.getRawRequest(config.base + "cache/index.php", {"x-cache-auth": "broccoli"});
		return this.getJson(request).then((result) => {
			console.log(result);
			return result;
		});
	}
	
	parseReleaseMp3s(shortDescription) {
		if (shortDescription) {
			var mp3Slugs = [];
			var el = $("<div></div>");
			el.html(shortDescription);		
			$("a", el).each(function(idx, a) {
				var href = $(this).attr("href");
				var splits = href.split("/");
				//console.log(splits);
				if (splits) {
					var slug = splits[splits.length - 2]
					mp3Slugs.push(slug);
				}
				
			});
			return mp3Slugs;
		} else {
			return [];
		}
	}
	
	getMp3s(slugs) {
		var mp3Url = wpBase + "/wp-json/wp/v2/media?slug=" + slugs.join() + "&fields=title.rendered,media_details.title,media_details.artist,media_details.track_number,media_details.length_formatted,source_url&per_page=100";
		var mp3Request = this.getRequest(mp3Url);
		return this.getJson(mp3Request).then(function(mp3JsonArray) {
			var mp3s = [];
			for (var idx in mp3JsonArray) {
				var mp3Json = mp3JsonArray[idx];
				var mp3 = {};
				if (mp3Json.media_details) {
					mp3.artist = mp3Json.media_details.artist;
					mp3.title = mp3Json.media_details.title;
					mp3.nr = mp3Json.media_details.track_number;
					mp3.length = mp3Json.media_details.length_formatted;
				} else {
					mp3.title = mp3Json.title.rendered;
				}
				mp3.src = mp3Json.source_url;				
				mp3s.push(mp3);
			}
			return mp3s;
		});
	}
	
	getMp3(slug) {
		var mp3Url = wpBase + "/wp-json/wp/v2/media?slug=" + slug;
		var mp3Request = this.getRequest(mp3Url);
		return this.getJson(mp3Request).then(function(mp3JsonArray) {
			var mp3Json = mp3JsonArray[0];
			var mp3 = {};
			if (mp3Json && mp3Json.media_details) {
				mp3.artist = mp3Json.media_details.artist;
				mp3.title = mp3Json.media_details.title;
				mp3.nr = mp3Json.media_details.track_number;
				mp3.src = mp3Json.source_url;
				mp3.length = mp3Json.media_details.length_formatted;				
			}	
			return mp3;			
		});
	}
	
	parseRelease(wcProduct) {
		var release = {};
		release.searchableTerms = [];
		release.show = wcProduct.status === 'publish';
		release.title = wcProduct.name;
		release.searchableTerms.push(release.title);
		release.description = wcProduct.description;
		release.mp3s = this.parseReleaseMp3s(wcProduct.short_description);
		release.price = wcProduct.price;
		release.publishedAt = this._formatDate(new Date(wcProduct.date_created));
		release.urbanWaves = false;
		release.radioJuicy = false;
		release.vinyl = false;
		release.tape = false;
		release.cd = false;
		for (var catIdx in wcProduct.categories) {
			var category = wcProduct.categories[catIdx];
			if (category.slug === "urbanwaves") {
				release.urbanWaves = true;
			} 
			if (category.slug === "radiojuicy") {
				release.radioJuicy = true;
			}
			if (category.slug === "vinyl") {
				release.vinyl = true;
			}
			if (category.slug === "tape") {
				release.tape = true;
			}
			if (category.slug === "cd") {
				release.cd = true;
			}
		}		
		release.artist = [];
		release.genre = [];
		release.various = false;
		var attributes = wcProduct.attributes;
		for (var attrIdx in attributes) {
			var attribute = attributes[attrIdx];
			if (attribute.name === "Artist") {
				var options = attribute.options;
				var artists = [];
				for (var optionIdx in options) {
					artists.push(options[optionIdx]);
					release.searchableTerms.push(options[optionIdx]);
				}
				release.artist = artists;
				release.various = release.artist[0].toLowerCase().includes("various");				
			}
			if (attribute.name.toLowerCase().includes("genre")) {
				var options = attribute.options;
				var genre = [];
				for (var optionIdx in options) {
					genre.push(options[optionIdx]);
					release.searchableTerms.push(options[optionIdx]);
				}				
				release.genre = genre;
			}
			if (attribute.name === "Spotify")
				release.spotify = attribute.options[0];
			if (attribute.name === "Deezer")
				release.deezer = attribute.options[0];
			if (attribute.name === "Tidal")
				release.tidal = attribute.options[0];
			if (attribute.name === "Bandcamp")
				release.bandcamp = attribute.options[0];
			if (attribute.name === "Apple Music")
				release.itunes = attribute.options[0];
			if (attribute.name === "YouTube Music")
				release.youtube = attribute.options[0];
			if (attribute.name === "Amazon")
				release.amazon = attribute.options[0];
			if (attribute.name === "HHV")
				release.hhv = attribute.options[0];
			if (attribute.name === "QRates")
				release.qrates = attribute.options[0];
			if (attribute.name === "Catalog Number")
				release.ref = attribute.options[0];
			if (attribute.name === "Release Year")
				release.year = attribute.options[0];
			if (attribute.name === "Release Date")
				release.releaseDate = attribute.options[0];
			if (attribute.name === "Preorder")
				release.preorder = attribute.options[0];
		}
		release.sortableDate = Date.parse(release.releaseDate + " " + release.year);
		release.inStock = wcProduct.in_stock;
		if (wcProduct.images[0] !== undefined) {
			release.cover = wcProduct.images[0].src;
		} else {
			release.cover = "/img/logo.png";
		}
		
		release.img = [];
		for (var imgIdx in wcProduct.images) {
			release.img.push(wcProduct.images[imgIdx].src);
		}
		release.slug = wcProduct.slug;
		release.id = wcProduct.id;
		if (wcProduct.variations.length > 0) {
			release.variations = wcProduct.variations;
			release.hasVariations = true;
		} else {
			release.variations = [];
			release.hasVariations = false;
		}
		return release;
	}
	
	getReleasesByVarious() {
		return this.getAllReleases(1, 100).then(function(releases) {			
			var variousReleases = [];
			for (var idx in releases) {
				var release = releases[idx];
				if (release.various) {
					variousReleases.push(release);
				}
			}
			return variousReleases;
		});
	}
	
	getReleasesByArtist(name) {
		return this.getAllReleases(1, 100).then(function(releases) {			
			var artistReleases = [];
			for (var idx in releases) {
				var release = releases[idx];
				if (release.artist.includes(name)) {
					artistReleases.push(release);
				}
			}
			return artistReleases;
		});
	}
			
	getAllReleases(page) {
		var obj = this;
		if (page === undefined) {
			page = 1;
		}
		return WooCommerce.getAsync('products/?page=' + page + '&per_page=20&status=publish&category=' + config.releaseCategory ).then(function(res) {
			var totalPages = res.headers["x-wp-totalpages"];
			if (page > totalPages) {
				return false;
			} else {
				var parsedReleases = [];
				var wcProducts = JSON.parse(res.body);
				for (var idx in wcProducts) {
					var release = obj.parseRelease(wcProducts[idx]);
					parsedReleases.push(release);
				}
				return parsedReleases;			
			}			
		});
	}
	
	parseMerchItem(wcProduct) {
		var merchItem = {};
		merchItem.show = wcProduct.status === 'publish';
		merchItem.title = wcProduct.name;
		merchItem.description = wcProduct.description;		
		merchItem.price = wcProduct.price;
		
		merchItem.inStock = wcProduct.in_stock;
		merchItem.cover = wcProduct.images[0].src;
		merchItem.img = [];
		for (var imgIdx in wcProduct.images) {
			merchItem.img.push(wcProduct.images[imgIdx].src);
		}
		merchItem.slug = wcProduct.slug;
		merchItem.id = wcProduct.id;
		if (wcProduct.variations.length > 0) {
			merchItem.variations = wcProduct.variations;
			merchItem.hasVariations = true;
		} else {
			merchItem.variations = [];
			merchItem.hasVariations = false;
		}
		return merchItem;
	}
	
	getMerchItemBySlug(slug) {
		var obj = this;
		return WooCommerce.getAsync("products?slug=" + slug).then(function(res) {
			var wcProduct = false;
			var wcProducts = JSON.parse(res.body);
			if (wcProducts.length > 0)
				wcProduct = wcProducts[0];
			
			if (wcProduct) {
				var merchItem = obj.parseMerchItem(wcProduct);
				return merchItem;
			} else {
				return false;
			}
		});
	}
	
	getMerchItemVariation(merchItemId, variationId) {
		return WooCommerce.getAsync("products/" + merchItemId + "/variations/" + variationId).then(function(variationRes) {
			var wcVariation = JSON.parse(variationRes.body);
			var variation = {};
			variation.price = wcVariation.price;
			variation.id = wcVariation.id;
			variation.inStock = wcVariation.in_stock;
			variation.description = wcVariation.description;
			var variationAttributes = wcVariation.attributes;
			for (var attrIdx in variationAttributes) {
				var attribute = variationAttributes[attrIdx];
				if (attribute.name === "Size") {
					variation.name = attribute.option;
					variation.slug = attribute.option.toLowerCase();
				}
			}
			return variation;
		});
	}
	
	getAllMerch(page, perPage) {
		var obj = this;
		return WooCommerce.getAsync('products/?page=' + page + '&per_page=' + perPage + '&status=publish&category=455' ).then(function(res) {
			var parsedMerch = [];
			var wcProducts = JSON.parse(res.body);
			for (var idx in wcProducts) {
				var merchItem = obj.parseMerchItem(wcProducts[idx]);
				parsedMerch.push(merchItem);
			}
			return parsedMerch;			
		});
	}
	
	getReleaseBySlug(slug) {
		var obj = this;
		return WooCommerce.getAsync("products?slug=" + slug).then(function(res) {
			var wcProduct = false;
			var wcProducts = JSON.parse(res.body);
			if (wcProducts.length > 0)
				wcProduct = wcProducts[0];
			
			if (wcProduct) {
				var release = obj.parseRelease(wcProduct);
				return release;
			} else {
				return false;
			}
		});
	}
	
	getReleaseVariation(releaseId, variationId) {
		return WooCommerce.getAsync("products/" + releaseId + "/variations/" + variationId).then(function(variationRes) {
			var wcVariation = JSON.parse(variationRes.body);
			console.log(wcVariation);
			var variation = {};
			variation.price = wcVariation.price;
			variation.id = wcVariation.id;
			variation.inStock = wcVariation.stock_status === "instock";
			variation.description = wcVariation.description;
			var variationAttributes = wcVariation.attributes;
			for (var attrIdx in variationAttributes) {
				var attribute = variationAttributes[attrIdx];
				if (attribute.name === "ProductType") {
					variation.name = attribute.option;
					variation.slug = attribute.option.toLowerCase();
				}
			}
			return variation;
		});
	}
	
	getArtistsByName(names) {
		return this.getArtists(1, 100).then(function (arts) {
			var artists = []
			for (var idx in arts) {
				var artist = arts[idx];
				if (names.includes(artist.name.replace("&#038;", "&amp;"))) {
					artists.push(artist);
				}
			}
			return artists;
		});
	}
	
	parseArtist(json) {
		var artist = {};
		artist.name = json.title.rendered;
		artist.slug = json.slug;
		artist.cover = json.featured_media_src_url;
		if (json.content)
			artist.content = json.content.rendered;
		return artist;
	}
	
	getArtists(page, perPage) {
		var obj = this; 
		var rosterId = config.rosterPageId;
		var rosterUrl = wpBase + "/wp-json/wp/v2/pages?parent=" + rosterId + "&order=desc&orderby=menu_order&fields=slug,content.rendered,title.rendered,featured_media_src_url&page=" + page + "&per_page=" + perPage;
		var request = this.getRequest(rosterUrl);
		return this.getJson(request).then(function (artistJson) {			
			var artists = [];
			for (var idx in artistJson) {
				var rosterItem = artistJson[idx];
				artists.push(obj.parseArtist(rosterItem));
			}
			return artists;
		});
	}
	
	getArtist(artistSlug) {
		var obj = this;
		var artistUrl = wpBase + "/wp-json/wp/v2/pages?slug=" + artistSlug + "&fields=slug,content.rendered,title.rendered,featured_media_src_url"
		var request = this.getRequest(artistUrl);
		return this.getJson(request).then(function (artistJson) {
			if (artistJson.length > 0) {
				return obj.parseArtist(artistJson[0]);
			} else {
				return null;
			}
		});
	}
	
	subscribe(email) {
		var subscribeUrl = wpBase + "/wp-content/plugins/newsletter/api/add.php?nk=" + config.subscribeApiKey + "&ne=" + email;
		var request = this.getRequest(subscribeUrl, {});
		return this.getText(request);
	}
	
	getCartSize() {		
		var cartUrl = wpBase + "/wp-json/wc/store/cart"
		var request = this.getRequest(cartUrl);
		return this.getJson(request).then(function (cart) {
			console.log(cart);
			return cart.items_count;
		});
	}
	
	addToCart(productId, variationId, variationSlug) {
		var addUrl = wpBase + "/cart/?add-to-cart=" + productId + "&variation_id=" + variationId + "&attribute_pa_producttype=" + variationSlug;
		var request = this.getRequest(addUrl);
		return this.getText(request).then(function (cartHtml) {
			return true;
		});
	}
	
	addToCartBySize(productId, sizeId, sizeSlug) {
		var addUrl = wpBase + "/cart/?add-to-cart=" + productId + "&variation_id=" + sizeId + "&attribute_pa_size=" + sizeSlug;
		var request = this.getRequest(addUrl);
		return this.getText(request).then(function (cartHtml) {
			console.log(cartHtml);
			return true;
		});
	}
	
	addToCartRaw(productId) {
		var addUrl = wpBase + "/cart/?add-to-cart=" + productId;
		var request = this.getRequest(addUrl);
		return this.getText(request).then(function (cartHtml) {
			return true;
		});
	}
	
	getFooterMenu() {
		var menuUrl = wpBase + "/wp-json/wp/v2/pages?parent=" + config.footerPageId + "&_fields=slug,title.rendered";
		var request = this.getRequest(menuUrl);
		return this.getJson(request).then(function (menuJson) {
			if (menuJson.length > 0) {
				var menu = [];
				for (var idx in menuJson) {
					var menuItemJson = menuJson[idx];
					var menuItem = {};
					menuItem.slug = menuItemJson.slug;
					menuItem.title = menuItemJson.title.rendered;
					menu.push(menuItem);
				}
				return menu;
			} else {
				return [];
			}
		});
	}
	
	getPageContents(slug) {
		var pageUrl = wpBase + "/wp-json/wp/v2/pages?slug=" + slug + "&_fields=title.rendered,content.rendered";
		var request = this.getRequest(pageUrl);
		return this.getJson(request).then(function (pageJson) {
			if (pageJson.length > 0) {
				var page = {};
				page.content = pageJson[0].content.rendered;
				page.title = pageJson[0].title.rendered;
				return page;
			} else {
				return {content: "", title: ""};
			}
		});
	}
	
	parseEvent(eventJson) {
		var months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];		
		var evt = {};
		evt.title = eventJson.title;
		evt.venue = eventJson.venue.venue;
		var time = eventJson.start_date_details;
		evt.time = months[Number(time.month) - 1] + " " + time.day + "<div class=\"EventTime\">" + time.hour + ":" + time.minutes + "</div>";
		evt.city = eventJson.venue.city;
		evt.country = eventJson.venue.country;
		return evt;
	}
	
	getEvents() {
		var eventsUrl = wpBase + "/wp-json/tribe/events/v1/events";
		var request = this.getRequest(eventsUrl);
		var obj = this;
		return this.getJson(request).then(function (eventsJson) {
			var events = [];
			if (eventsJson.events.length) {
				for (var idx in eventsJson.events) {
					var eventJson = eventsJson.events[idx];
					var evt = obj.parseEvent(eventJson);
					events.push(evt);
				}
			}
			return events;
		});
	}
	
	parsePost(postJson) {
		var post = {};
		post.slug = postJson.slug;
		post.content = postJson.content.rendered;
		post.date = postJson.date_gmt;
		post.title = postJson.title.rendered;
		return post;
	}
	
	parseArchivePost(archivePostJson) {
		var post = {};
		post.slug = archivePostJson.slug;
		post.content = archivePostJson.content.rendered;
		post.date = archivePostJson.date_gmt;
		post.title = archivePostJson.title.rendered;
		if (archivePostJson.featured_media_src_url !== null)
			post.cover = archivePostJson.featured_media_src_url;
		else 
			post.cover = null;
		return post;
	}
	
	getArchiveBlogPosts(page, perPage) {
		var postsUrl = wpBase + "/wp-json/wp/v2/posts?_fields=slug,title.rendered,content.rendered,date_gmt,featured_media_src_url&page=" + page + "&per_page=" + perPage;
		var request = this.getRequest(postsUrl);
		var obj = this;
		return this.getJson(request).then(function (postsJson) {			
			if (postsJson.length > 0) {
				var posts = [];
				for (var idx in postsJson) {
					posts.push(obj.parseArchivePost(postsJson[idx]));
				}
				return posts;
			} else {
				return null;
			}
		});
	}
	
	getBlogPosts(page, perPage) {
		var postsUrl = wpBase + "/wp-json/wp/v2/posts?_fields=slug,title.rendered,content.rendered,date_gmt&page=" + page + "&per_page=" + perPage;
		var request = this.getRequest(postsUrl);
		var obj = this;
		return this.getJson(request).then(function (postsJson) {
			//console.log(postsJson);
			if (postsJson.length > 0) {
				var posts = [];
				for (var idx in postsJson) {
					posts.push(obj.parsePost(postsJson[idx]));
				}
				return posts;
			} else {
				return null;
			}
		});
	}
	
	getBlogPostBySlug(slug) {
		//console.log(slug);
		var postUrl = wpBase + "/wp-json/wp/v2/posts?slug=" + slug + "&_fields=slug,title.rendered,content.rendered,date_gmt";
		var request = this.getRequest(postUrl);
		var obj = this;
		return this.getJson(request).then(function (postsJson) {			
			//console.log(postsJson);
			if (postsJson.length === 1) {
				var post = obj.parsePost(postsJson[0]);
				return post;
			} else {
				return null;
			}
		});
	}

}

var Ajax = new AjaxCalls();

export { Ajax, WooCommerce };