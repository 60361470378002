import React, { Component } from 'react';
import {  Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import $ from 'jquery';
import './AppSmall.css';
import './AppLarge.css';
import './AppMobile.css';
import './mobile-menu.css';


import { Menu } from './components/Menu.js';
import { MobileMenu } from './components/MobileMenu.js';
import { Releases } from './components/Releases.js';
import { ReleaseDetails } from './components/ReleaseDetails.js';
import { Roster } from './components/Roster.js';
import { RosterArtistDetails } from './components/RosterArtistDetails.js';
import { Cart } from './components/Cart.js';
import { Account } from './components/Account.js';
import { Footer } from './components/Footer.js';
import { PageContents } from './components/PageContents.js';
import { Redeem } from './components/Redeem.js';
import { Landing4 } from './components/Landing4.js';
import { MobileShop } from './components/MobileShop.js';
import { MobileReleaseDetails } from './components/MobileReleaseDetails.js';
import { AudioPlaylistLanding2 } from './components/AudioPlaylistLanding2.js';

import { Ajax } from './ajax/Ajax';
import config from './config/Config';

class App extends Component {
	
	constructor(props) {
		super(props);
		this.state = {ready: false, cartSize: 0, page: 1, player: false, releases: [], filteredReleases: [], shopFilter: {label: {all: true, urbanWaves: false, radioJuicy: false}, filter: [], inStock: {all: true, vinyl: false, tape: false, cd: false}, genres: {}}};
		this.updateCartSize = this.updateCartSize.bind(this);
		this.addReleases = this.addReleases.bind(this);
		this.updateFilter = this.updateFilter.bind(this);
	}

	updateCartSize() {
		var obj = this;
		Ajax.getCartSize().then(function(size) {
			obj.setState({cartSize: size});
		});
	}

	addReleases(tryCache) {
		var obj = this;	
		if (tryCache) {
			Ajax.getCachedReleases().then(function(cachedReleases) {
				if (cachedReleases.cache === undefined) {
					var filteredReleases = cachedReleases.filter((release) => {
						return ((release.radioJuicy && config.radioJuicy) || (release.urbanWaves && config.urbanWaves));
					});
					obj.setState({ready: filteredReleases.length > 0, releases: cachedReleases, filteredReleases: filteredReleases});
				} else {
					obj.addReleases(false);
				}
			});	
		} else {
			Ajax.getAllReleases(obj.state.page).then(function(extraReleases) {
				if (extraReleases) {
					var releases = obj.state.releases;
					releases.push(...extraReleases);
					releases.sort(function(a, b) { return b.sortableDate - a.sortableDate });
					var filteredReleases = releases.filter((release) => {
						return ((release.radioJuicy && config.radioJuicy) || (release.urbanWaves && config.urbanWaves));
					});
					obj.setState({ready: filteredReleases.length > 0, releases: releases, filteredReleases: filteredReleases, page: obj.state.page + 1}, () => { obj.addReleases(false); } );
				} else {
					Ajax.cacheReleases(obj.state.releases);
				}			
			});
		}		
	}
	
	updateFilter(filter) {
		this.setState({shopFilter: filter});
	}

	componentWillMount() {
		this.updateCartSize();		
		this.addReleases(true);
	}

  render() {
	  var ready = this.state.ready;	  
	  var mobile = $(window).width() <= 1361;
    return (
	<div className="Wrapper">
		<Helmet>
			<title>{config.title}</title>
			<link rel="shortcut icon" href={"/img/" + config.favicon} />
		</Helmet>
		{ready && ! mobile ? (<AudioPlaylistLanding2 ref={(player) => {if (!this.state.player) this.setState({player: player});}} release={this.state.filteredReleases[0]} track={0} />) : null }
		<Menu cartSize={this.state.cartSize} />
		<MobileMenu />
		  <Switch>
			<div className="ContentWrapper">
				
				{ready ? (<Route 
				exact path='/' 
				render={mobile ? (props) => <MobileShop {...props} releases={this.state.filteredReleases} /> : (props) => <Landing4 {...props} releases={this.state.filteredReleases} player={this.state.player}/>}
				/>) : null }
				
				{ready ? (<Route 
				exact path='/shop' 
				render={mobile ? (props) => <MobileShop {...props} releases={this.state.filteredReleases} /> : (props) => <Releases {...props} shopFilter={this.state.shopFilter} updateFilter={this.updateFilter} releases={this.state.releases} player={this.state.player}/>}
				/>) : null }				
				
				{ready ? (<Route 
				path='/album/:slug' 
				render={mobile ? (props) => <MobileReleaseDetails {...props} cartFunction={this.updateCartSize} /> : (props) => <ReleaseDetails {...props} cartFunction={this.updateCartSize} player={this.state.player} />}
				/>) : null }
				
				<Route path='/page/:slug' component={PageContents}/>
				<Route exact path="/roster" component={Roster} />
				{ ready ? (<Route 
				path='/artist/:slug' 
				render={(props) => <RosterArtistDetails {...props} releases={this.state.releases} />} />) : null }
			
				<Route exact path="/cart" component={Cart} />
				<Route exact path="/account" component={Account} />
				<Route exact path="/redeem" component={Redeem} />			
			</div>
		</Switch>
				
		{! ready ? (<div className="LandingLoader"><div className="Loader"><img src="/img/loader.gif" alt="LOADING" /></div></div>) : null }
		<Footer />
		<div className="Clear"/>
      </div>
    );
  }
}

export default App;
