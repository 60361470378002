import React, { Component } from 'react';
import { Ajax } from '../ajax/Ajax';
import { Link } from 'react-router-dom';

class RosterArtistDetails extends Component {
	
	constructor(props) {
		super(props);
		this.state = {artist: false, releases: this.props.releases, filteredReleases: []};
		this.loadVarious = this.loadVarious.bind(this);
		this.filterReleases = this.filterReleases.bind(this);
	}
	
	loadVarious(release) {
		var obj = this;
		var name = this.state.artist.name;
		return function(mp3s) {
			for (var mp3Idx in mp3s) {
				var mp3 = mp3s[mp3Idx];
				if (mp3.artist === name) {
					obj.setState({filteredReleases: [...obj.state.filteredReleases, release]});
					break;
				}
			}
		}
	}

	filterReleases() {
		if (this.state.artist !== undefined) {
			var releases = this.state.releases;
			var obj = this;
			var filtered = releases.filter((release) => {
				return release.artist.includes(obj.state.artist.name);
			});
			this.setState({filteredReleases: filtered});
			// do various artist releases
			for (var idx in releases) {
				var release = releases[idx];
				if (release.various) {
					Ajax.getMp3s(release.mp3s).then(obj.loadVarious(release));
				}
			}
		}		
	}

	componentDidUpdate(prevProps) {
		if (this.props.releases.length != prevProps.releases.length) {
			this.setState({releases: this.props.releases}, this.filterReleases);
		}
	}
	
	
	componentWillMount() {
		var obj = this;
		var slug = this.props.match.params.slug;
		Ajax.getArtist(slug).then(function(artist) {
			obj.setState({artist: artist}, obj.filterReleases);
		});

	}
	
	render() {
		if (this.state.artist !== undefined) {
			var title = this.state.artist.name;
			var cover = this.state.artist.cover;			
			var content = this.state.artist.content;
			var filteredReleases = this.state.filteredReleases;
	
			return (
			<div className="RosterArtistDetails">
				{(cover !== undefined) && cover ? (<div className="RosterArtistDetailsCover"><img src={cover} alt="cover"/></div>) : null }
				<div className="RosterArtistDetailsText">
					<div className="RosterArtistDetailsName" dangerouslySetInnerHTML={ {__html: title} } />
					<div className="RosterArtistDetailsContent" dangerouslySetInnerHTML={ {__html: content} } />				
				</div>	
				<div className="RosterArtistDetailsReleases">
					<div className="RosterArtistDetailsReleasesTitle">Appears on</div>
					{ filteredReleases ? filteredReleases.map(function(release, key) {
						var rCover = release.cover;
						rCover.includes("-scaled.jpg") ? rCover = rCover.replace("-scaled.jpg", "-300x300.jpg") : rCover = rCover.replace(".jpg", "-300x300.jpg");
						return (							
							<Link key={key} to={"/album/" + release.slug}><div key={key} className="RosterArtistDetailsRelease">
								<div className="RosterArtistDetailsReleaseCover" style={{ backgroundImage : "url('" + rCover + "')"  }} />
								<div className="RosterArtistDetailsReleaseName" dangerouslySetInnerHTML={ {__html: release.title} } />
							</div>
							</Link>);
						}) : null
					}
				</div>
			</div>
			);
		} else {
			return (<div className="RosterArtistDetails"/>);
		}
		
	}
	
}

export { RosterArtistDetails };