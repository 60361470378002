import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { Ajax } from '../ajax/Ajax';
import $ from 'jquery';
import WaveSurfer from 'wavesurfer.js'

class AudioPlaylistLanding2 extends Component {
	
	constructor(props) {
		super(props);		
		this.state = {
			releases: this.props.releases, 
			currentCover: null, 
			currentMp3: null, 
			playing: false, 
			currentVolume: 0.5, 
			currentIdx: 0, 
			covers: [], 
			mp3s: []}
		
		this.handlePlay = this.handlePlay.bind(this);
		this.handleStop = this.handleStop.bind(this);
		this.handleNext = this.handleNext.bind(this);
		this.handlePrev = this.handlePrev.bind(this);
		this.handleTimeUpdate = this.handleTimeUpdate.bind(this);
		this.handleLoad = this.handleLoad.bind(this);
		this.handleSeek = this.handleSeek.bind(this);
		this.handleSeekFromWaveform = this.handleSeekFromWaveform.bind(this);
		this.handleVolumeChange = this.handleVolumeChange.bind(this);
		this.handleVolumeUpdate = this.handleVolumeUpdate.bind(this);
		
		this.setAlbum = this.setAlbum.bind(this);
		
		this.wavesurfer = null;
		this.waveElement = null;
		
		
	}
	
	componentWillMount() {	
		this.setAlbum(this.props.release, this.props.track);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.release && (prevProps.release !== undefined))
			if (prevProps.release.slug != this.props.release.slug) {
				this.setAlbum(this.props.release, this.props.track);
			}
	}
	
	componentDidMount() {
		var obj = this;
		var el = obj.waveElement;
		this.wavesurfer = WaveSurfer.create({
			container: el,
			waveColor: 'black',
			progressColor: 'orange',
			cursorWidth: 0,
			normalize: true,
			barWidth: 3,
			height: 80,
			hideScrollBar: true,
			responsive: true
		});		
	}

	setAlbum(release, mp3s, track, play) {
		console.log(release);
		if (release && (release !== undefined)) {
			var obj = this;
			if (track === undefined) {
				track = 0;
			}
			var cover = release.cover;		
			if (mp3s) {
				obj.setState({
					currentMp3: mp3s[track],
					currentCover: cover,
					currentIdx: track,
					currentSlug: release.slug,
					covers: [],
					mp3s: mp3s
				});
				obj._setSource(mp3s[track], ! obj.state.playing);
				if (play) obj.handlePlay();
			} else {			
				var mp3Slugs = release.mp3s;
				Ajax.getMp3s(mp3Slugs).then(function(mp3s) {
					for (var i = 0; i < mp3s.length; i++) {
						var mp3 = mp3s[i];
						if (mp3.nr === undefined) {
							mp3.nr = 1;
						}
					}
					mp3s.sort(function(a, b) { return a.nr - b.nr });
					obj.setState({
						currentMp3: mp3s[track],
						currentCover: cover,
						currentIdx: track,
						currentSlug: release.slug,
						covers: [],
						mp3s: mp3s
					});
					obj._setSource(mp3s[track], ! obj.state.playing);
					if (play) obj.handlePlay();
				});	
			}
		}			
	}
	
	_setSource(mp3, hold) {
		//mp3 = {src: "http://localhost:3000/birds.mp3"};
		document.getElementById('playerSrc').src = mp3.src;
		document.getElementById('player').load();
		$("#progressBar").width(0);
		if (! hold)
			this.handlePlay();
		if (this.wavesurfer != null) {
			this.wavesurfer.destroy();
			var el = this.waveElement;
			this.wavesurfer = WaveSurfer.create({
				container: el,
				waveColor: 'black',
				progressColor: 'orange',
				cursorWidth: 0,
				normalize: true,
				barWidth: 1,
				height: 80,
				hideScrollBar: true,
				responsive: true
			});	
			this.wavesurfer.load(mp3.src);
		}
	}
	
	handlePlay() {
		document.getElementById('player').play();
		this.setState({playing: true});
	}
	
	handleStop() {
		document.getElementById('player').pause();
		this.setState({playing: false});
	}
	
	handleNext() {
		var idx;
		if (this.state.currentIdx >= this.state.mp3s.length - 1) {
			idx = 0;
		} else {
			idx = this.state.currentIdx;
			idx++;
		}		
		var currentMp3 = this.state.mp3s[idx];
		this.setState({currentMp3: currentMp3, currentIdx: idx});
		this._setSource(currentMp3, false);
	}
	
	handlePrev() {	
		var idx;	
		if (this.state.currentIdx > 0) {
			idx = this.state.currentIdx;
			idx--;
		} else {
			idx = this.state.mp3s.length - 1;
		}
		var currentMp3 = this.state.mp3s[idx];
		this.setState({currentMp3: currentMp3, currentIdx: idx});
		this._setSource(currentMp3, false);
	}
	
	_formatTime(time) {
		if (isNaN(time))
			return "";
		var hours   = Math.floor(time / 3600);
		var minutes = Math.floor((time - (hours * 3600)) / 60);
		var seconds = Math.floor(time - (hours * 3600) - (minutes * 60));
		var formattedHours, formattedMinutes, formattedSeconds
		hours < 10 ? formattedHours = "0"+hours : formattedHours = hours;
		minutes < 10 ? formattedMinutes = "0"+minutes : formattedMinutes = minutes;
		seconds < 10 ? formattedSeconds = "0"+seconds : formattedSeconds = seconds;
		
		if (hours > 0)
			return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
		else
			return formattedMinutes + ":" + formattedSeconds;		
	}
	
	handleTimeUpdate() {
		var player = document.getElementById('player');
		var duration = player.duration;
		var curr = player.currentTime;
		var ratio = curr/duration;
		if (Number.isNaN(ratio)) {
			ratio = 0;
		}
		$("#progressBar").width(ratio * 100 + '%');		
		$("#time").text("-" + this._formatTime(duration - curr));
		this.wavesurfer.seekTo(ratio);
	}
	
	handleVolumeUpdate() {
		var player = document.getElementById('player');
		var volume = player.volume;
		$("#volumeBar").width(volume * 100 + '%');		
	}
	
	handleLoad() {
		var player = document.getElementById('player');
		var duration = player.duration;
		$("#progressBar").width(0);		
		$("#time").text("-" + this._formatTime(duration));
		player.volume = this.state.currentVolume;
		
	}
	
	handleVolumeChange(e) {
		var volumeBar = $("#volumeBarContainer");
		var w = volumeBar.width();
		var offset = volumeBar.offset();
		var x = e.clientX - offset.left;
		var player = document.getElementById('player');
		player.volume = Math.max(0, Math.min(1, x / w));
		this.setState({currentVolume: Math.max(0, Math.min(1, x / w))})
	}
	
	handleSeek(e) {
		var progressBar = $("#progressBarContainer");
		var w = progressBar.width();		
		var offset = progressBar.offset();
		var x = e.clientX - offset.left;
		var player = document.getElementById('player');
		player.currentTime = Math.floor(player.duration * Math.max(0, Math.min(1, (x / w))));
		if (! this.state.playing)
			this.handlePlay();
	}
	
	handleSeekFromWaveform(e) {
		var progressBar = $("#waveform");
		var w = progressBar.width();
		var offset = progressBar.offset();
		var x = e.clientX - offset.left;
		var player = document.getElementById('player');
		player.currentTime = Math.floor(player.duration * Math.max(0, Math.min(1, (x / w))));
		if (! this.state.playing)
			this.handlePlay();
	}	

	render() {
		var obj = this;
		var currentCover = this.state.currentCover;
		var currentSlug = this.state.currentSlug;
		var mp3 = this.state.currentMp3;		
		if (mp3) {
			return (
			<div className="LandingPlayer">
				<Link to={"/album/" + currentSlug}>
					<div className="LandingPlayerLeft" style={{backgroundImage:  "url('" +  currentCover + "')"}}>
					
					</div>
				</Link>
				
				<div className="LandingPlayerRight">
					
					<div className="AudioPlaylistLanding2">
						<audio className="AudioPlaylistLandingHiddenPlayer2" id="player" onEnded={this.handleNext} onTimeUpdate={this.handleTimeUpdate} onLoadedMetadata={this.handleLoad} onVolumeChange={this.handleVolumeUpdate}>
							<source id="playerSrc" src={mp3.src}/>
						</audio>				
						<div className="AudioPlaylistLandingControls2">
							{ this.state.playing ? (<div className="AudioPlaylistLandingStop2 AudioPlaylistLandingButton2" onClick={this.handleStop}><FontAwesome name="pause"/></div>) :
							(<div className="AudioPlaylistLandingPlay2 AudioPlaylistLandingButton2" onClick={this.handlePlay}><FontAwesome name="play"/></div>) }
							<div className="AudioPlaylistLandingPrev2 AudioPlaylistLandingButton2" onClick={this.handlePrev}><FontAwesome name="step-backward"/></div>
							<div className="AudioPlaylistLandingNext2 AudioPlaylistLandingButton2" onClick={this.handleNext}><FontAwesome name="step-forward"/></div>												
							<div className="AudioPlaylistLandingTime2" id="time"></div>
							<div className="AudioPlaylistLandingVolumeBarContainer2" onClick={this.handleVolumeChange} id="volumeBarContainer">
								<div className="AudioPlaylistLandingVolumeBar2" id="volumeBar"/><FontAwesome name="volume-up"/>
							</div>
						</div>				
					</div>
					<div id="waveform" className='waveform2' onClick={this.handleSeekFromWaveform}>
						<div ref={(wave) => {this.waveElement = wave;}} className='wave'></div>
					</div>
					<div className="LandingPlayerTitle">
						
						<div className="LandingPlayerArtistAndSong" dangerouslySetInnerHTML={ {__html: mp3.artist + " - " + mp3.title} } />
					</div>
				</div>
				
			</div>
			);	
		} else {
			return (<div className='waveform2' style={{display: "none"}}>
						<div ref={(wave) => {this.waveElement = wave;}} className='wave'></div>
					</div>);
		}
		
	}
}

export { AudioPlaylistLanding2 };