import React, { Component } from 'react';
import { Ajax } from '../ajax/Ajax';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';

class LandingRelease extends Component {
	
	constructor(props) {
		super(props);
		this.state = {mp3s: [], release: this.props.release, artists: false, player: this.props.player, noMargin: this.props.noMargin};
		this.mergeArtist = this.mergeArtist.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.loadRelease = this.loadRelease.bind(this);
	}	

	loadRelease() {
		var obj = this;
		var release = this.state.release;
		var artistNames = release.artist;
		Ajax.getArtistsByName(artistNames).then(function(artists) {
			obj.setState({artists: artists});
			return obj.state.release;
		}).then((release) => {
			var mp3Slugs = release.mp3s;
			if (mp3Slugs.length > 0) {
				Ajax.getMp3s(mp3Slugs).then(function(mp3s) {
					for (var i = 0; i < mp3s.length; i++) {
						var mp3 = mp3s[i];
						if (mp3.nr === undefined) {
							mp3.nr = 1;
						}
					}
					mp3s.sort(function(a, b) { return a.nr - b.nr });	
					obj.setState({mp3s : mp3s});
				});			
			}
		});
	}
	
	componentWillMount() {
		this.loadRelease();
	}

	componentDidUpdate(prevProps) {
		if(this.props.release.slug !== prevProps.release.slug) {
			this.setState({release: this.props.release, mp3s: []}, this.loadRelease);
		}
	} 
	
	mergeArtist(artistName) {		
		var artists = this.state.artists;
		for (var artistIdx in artists) {
			var artist = artists[artistIdx];
			if (artist.name.replace("&#038;", "&amp;") === artistName) 
				return artist;
		}
		return false;
	}

	handleSelect(idx) {
		var obj = this;
		
		return () => {
			console.log(obj.state.mp3s);
			obj.state.player.setAlbum(obj.state.release, obj.state.mp3s, idx, true);
		}
	}
	
	render() {
		
		var slug = this.state.release.slug;
		var title = this.state.release.title;
		var artists = this.state.release.artist;
		var cover = this.state.release.cover;
		var spotify = this.state.release.spotify;
		var bandcamp = this.state.release.bandcamp;
		var itunes = this.state.release.itunes;
		var youtube = this.state.release.youtube;
		var deezer = this.state.release.deezer;
		var amazon = this.state.release.amazon;
		var mp3s = this.state.mp3s;
		var various = false;
		
		var obj = this;
	
		return (
		<div className="LandingRelease">
			<div className="LandingReleaseCover">
				<Link to={"/album/" + slug}><div className="LandingReleaseCoverMain" style={{ backgroundImage: "url('" + cover + "')"}} id="cover" ></div></Link>
				
			</div>
			<div className="LandingReleaseText">
				<div className="LandingReleaseTitle" dangerouslySetInnerHTML={ {__html: title} } />
				<div className="LandingReleaseArtist">
				{ artists ? artists.map(function(artist, key) {
						var mergedArtist = obj.mergeArtist(artist);
						if (mergedArtist)
							return (<span key={key} className="LandingReleaseArtistName Clickable" dangerouslySetInnerHTML={ {__html: mergedArtist.name } }  onClick={ function() { window.location.href = "/artist/" + mergedArtist.slug; }}/>);
						else 
							return (<span key={key} className="LandingReleaseArtistName" dangerouslySetInnerHTML={ {__html: artist } } />)
					}) : null
				}
				</div>
				<div className="LandingReleaseLinks">
					{ spotify ? (<div className="LandingReleaseLink Spotify"><a href={spotify} target="_blank" rel="noopener noreferrer"><FontAwesome name="spotify" /></a></div>) : null }
					{ bandcamp ? (<div className="LandingReleaseLink Bandcamp"><a href={bandcamp} target="_blank" rel="noopener noreferrer"><FontAwesome name="bandcamp" /></a></div>) : null }
					{ itunes ? (<div className="LandingReleaseLink ITunes"><a href={itunes} target="_blank" rel="noopener noreferrer"><FontAwesome name="apple" /></a></div>) : null }
					{ youtube ? (<div className="LandingReleaseLink Youtube"><a href={youtube} target="_blank" rel="noopener noreferrer"><FontAwesome name="youtube" /></a></div>) : null }
					{ amazon ? (<div className="LandingReleaseLink Amazon"><a href={amazon} target="_blank" rel="noopener noreferrer"><FontAwesome name="amazon" /></a></div>) : null }
					{ deezer ? (<div className="LandingReleaseLink Deezer"><a href={deezer} target="_blank" rel="noopener noreferrer"><img src="/img/deezer.png" /></a></div>) : null }
				</div>
				<div className="LandingAudioPlaylistItems">
					{ mp3s ? mp3s.map(function(mp3, key) {
						var entry = mp3.nr + ". " + mp3.title; 
						if (various)
							entry += " (" + mp3.artist + ")";
						return (
							<div key={key} className="AudioPlaylistItem" onClick={obj.handleSelect(key)}>
								<FontAwesome name="play"/>
								<span className="AudioPlaylistItemTitle" dangerouslySetInnerHTML={ {__html:entry} } />
								<span className="AudioPlaylistItemLength" dangerouslySetInnerHTML={ {__html:mp3.length} } />
							</div>
							);
						}) : null
					}
				</div>
			</div>			
		</div>
		);
	}
	
}


export { LandingRelease };
